.button-style {

  &.-nav-button {
    border: solid rem(2px) colors.line;
    border-radius: rem(4px);
    padding: rem(10px) rem(16px);

    transition: all 0.2s;
    &:hover {
      background: colors.text;
      color: #506e96;
    }
  }
}
