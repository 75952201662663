@font-face {
  font-family: 'UnitRoundedPro';
  src: url('../fonts/UnitRoundedPro.otf') format('opentype');
}

@font-face {
  font-family: 'UnitRoundedPro';
  font-weight: bold;
  src: url('../fonts/UnitRoundedPro-Medi.otf') format('opentype');
}
