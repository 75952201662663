.abs-layout {

  &.-r3-2 {
    max-width: 150vh;
  }

  &.-center {
    margin-left: auto;
    margin-right: auto;
  }

  > .item {

    &.-w2-5 {
      width: 40%;
    }

    &.-w1-2 {
      width: 50%;
    }
  }
}
