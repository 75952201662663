text-style = {}

text-style.h1 = {
  font-weight: bold,
  font-size: rem(54px),
  line-height: rem(54px),
}

text-style.h2 = {
  font-weight: bold,
  font-size: rem(13px),
  line-height: rem(26px),
  text-transform: uppercase,
  letter-spacing: rem(1.63px),
  margin-bottom: rem(24px),
}

text-style.h3 = {
  font-weight: bold,
  font-size: rem(24px),
  line-height: rem(34px),
  margin-bottom: rem(16px),
}

text-style.para = {
  font-size: rem(18px),
  line-height: rem(28px),
  margin-bottom: rem(10px),
}

text-style.list {
  margin-bottom: rem(10px),
}

text-style.list-item {
  margin-bottom: rem(10px),
}

text-style.button-label = {
  font-weight: bold,
  font-size: rem(16px),
  line-height: rem(16px),
  letter-spacing: rem(0.5px),
}

text-style.center = {
  text-align: center,
}

text-style.em = {
  font-weight: bold,
}

text-style.drop-shadow = {
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.25),
}
